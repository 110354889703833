
import { Vue, Component } from 'vue-property-decorator';
import { IformCreateOption } from 'ant-design-vue/types/form/form';
import User from '@/models/User';
import { RouteNames } from '@/enums/routes/RouteNames';
import RoltekLogo from '@/icons/RoltekLogo.vue';
import RoltekHeaderLogo from '@/icons/RoltekHeaderLogo.vue';
import { Col } from 'ant-design-vue';
import { Row } from 'ant-design-vue';
import RoltekBanner from '@/icons/RoltekBanner.vue';

@Component({
  name: 'ForgotPassword',
  components: {
    RoltekLogo,
    RoltekHeaderLogo,
    RoltekBanner,
    Col,
    Row,
  },
})
export default class ForgotPassword extends Vue {
  private httpRequestStarted: boolean = false;

  private get form() {
    return this.$form.createForm(this, {
      name: 'horizontal_login',
    } as IformCreateOption);
  }

  private handleSubmit(event: Event) {
    event.preventDefault();

    this.form.validateFields(
      // @ts-ignore
      async (err: Error, { email }: { email: string }) => {
        if (!err) {
          this.httpRequestStarted = true;
          try {
            await User.requestPassword(email);
          } catch (e) {
            this.$notification.error({
              message: this.$t('Dogodila se greška') as string,
              description: this.$t(
                'Provjerite da ste unijeli valjanu email adresu',
              ) as string,
            });
            return;
          } finally {
            this.httpRequestStarted = false;
          }
          this.$notification.success({
            message: this.$t('Zahtjev uspješno poslan!') as string,
            description: this.$t(
              'Provjerite svoj poštanski sandučić i pratite upute u emailu!',
            ) as string,
            duration: 8,
          });
          this.$router.push({ name: RouteNames.login });
        }
      },
    );
  }
}
